import React, { useEffect, useState } from "react";
import "./Blog.scss";
import Header from "../../Components/Header/Header";

import Footer from "../../Components/rus/Footer/Footer";
import axios from "axios";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
interface Article {
  Title: string;
  Author: string;
  Content: string;
}
const Blog = observer(() => {
  const [article, setArticle] = useState<Article>();
  let params = useParams();
  useEffect(() => {
    if (params.id !== undefined) {
      axios
        .get("https://ae.exchange/lk/blog/" + params.id)
        .then(function (res) {
          console.log(res);
          setArticle(res.data);
        });
    }
  }, []);

  return (
    <div className="body">
      <Header />

      <div className="page_content">
        <div className="view_article">
          <ViewArticle article={article} />
        </div>
      </div>
      <div className="page_footer">
        <Footer />
      </div>
    </div>
  );
});
export default Blog;

const ViewArticle = observer(
  ({ article }: { article: Article | undefined }) => {
    if (article) {
      return (
        <div>
          <div className="view_article_title">{article.Title}</div>
          <div className="view_article_author">{article.Author}</div>
          <div className="view_article_content"  dangerouslySetInnerHTML={{__html: article.Content}}></div>
        </div>
      );
    }
    return <div></div>;
  }
);
