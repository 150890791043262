import React from "react";
import { Routes, Route } from "react-router-dom";
import SignInComponent from "./Pages/Login/Login";
import Chart from "./Pages/Charts/TestChart";
import Index from "./Pages/Index/Index";
import Docs from "./Pages/Docs/Docs";
import Faq from "./Pages/Faq/Faq";
import News from "./Pages/News/News";
import Onbidding from "./Pages/Onbidding/Onbidding";
import StartTrade from "./Pages/StartTrade/StartTrade";
import storeDef from "./Store/Default";
import storeInstruments from "./Store/Instruments";
import Products from "./Pages/ServicesAndProducts/ServicesAndProducts"
import Club from "./Pages/Club/Club"
import Blog from "./Pages/Blog/Blog";

import "./App.css";

function App() {
  let selL = localStorage.getItem("ae-lang-select");
  if (selL === null) {
    selL = "2";
    localStorage.setItem("auth.langSelect", "1");
  }
  storeDef.setLang(+selL);
  storeInstruments.Loads()
  
  
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/pdocs" element={<Docs />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/onbidding" element={<Onbidding />} />
        <Route path="/news" element={<News />} />
        <Route path="/login" element={<SignInComponent />} />
        <Route path="/chart" element={<Chart />} />
        <Route path="/starttrade" element={<StartTrade />} />
        <Route path="/products" element={<Products />} />
        <Route path="/club" element={<Club />} />
        <Route path="/blog/:id" element={<Blog />} />
      </Routes>
    </div>
  );
}

export default App;
